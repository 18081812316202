import React, { useContext, useEffect, useState, useRef } from "react";
import Sliders from "../Components/Sliders";
import FAQ from "../Components/FAQ";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Store } from "../Phantom/PhantomWallet";
import Loader from "../Components/Loader";
import { Footer, Header } from "../Components";
import CanvasJSReact from "@canvasjs/react-charts";

import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import Countdown from "react-countdown";
// import CountdownComponent from "../Components/CountDown";

const data = [
  { value: 10, label: "Team" },
  { value: 10, label: "Marketing" },
  { value: 5, label: "Cex listing" },
  { value: 30, label: "Presale" },
  { value: 5, label: "Airdrops" },
  { value: 40, label: "Liquidity" },
];

const size = {
  width: 800,
  height: 400,
};

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const LandingPage = () => {
  const chartRef = useRef(null);

  const [active, setActive] = useState(0);
  const [tokens, setTokens] = useState(0);
  const [tokensPrice, setTokensPrice] = useState(0);
  const [error, setError] = useState(false);

  const {
    publicKey,
    getUserDataValue,
    user,
    participateUser,
    toatlSoldTokens,
    purchasedToken,
    totalRaisedSol,
    loader,
    getIsUser,
    tokenPrice,
    buyWithSol,
    getAdminSectionValues,
    isPresaleStart,
    isInitiliseStatePDA,
  } = useContext(Store);

  // indexLabelPlacement: "inside",
  // indexLabelFontColor: "#fff",
  // indexLabelFontSize: 14,
  // indexLabel: "{label}: {y}%",
  // startAngle: -90,
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const [chartOptions, setChartOptions] = useState({
    // animationEnabled: true,
    // exportEnabled: true,
    // theme: "dark2", // "light1", "dark1", "dark2"
    // title:{
    //     text: "Trip Expenses"
    // },
    data: [
      {
        type: "pie",
        indexLabelFontColor: "#000",
        indexLabel: "{label}: {y}%",
        startAngle: -90,
        dataPoints: [
          { y: 10, label: "Team tokens" },
          { y: 10, label: "Marketing" },
          { y: 5, label: "Cex listings" },
          { y: 30, label: "Presale" },
          { y: 5, label: "Airdrops" },
          { y: 40, label: "Liquidity" },
        ],
      },
    ],
  });

  // Function to change the color of a slice
  const changeSliceColor = () => {
    if (chartRef.current) {
      // Update color of a specific data point (slice)
      chartOptions.data[0].dataPoints[0].color = "#025B95";
      chartOptions.data[0].dataPoints[1].color = "#2E84B8";
      chartOptions.data[0].dataPoints[2].color = "#52A8D9";
      chartOptions.data[0].dataPoints[3].color = "#8ACDF8";
      chartOptions.data[0].dataPoints[4].color = "#CDEDFB";
      chartOptions.data[0].dataPoints[5].color = "#004571";

      // Trigger re-rendering of the chart
      chartRef.current.render();
    }
  };

  useEffect(() => {
    // Example of changing color after 2 seconds (just for demonstration)
    const timeout = setTimeout(() => {
      changeSliceColor();
    }, 1000);

    return () => clearTimeout(timeout);
  }, []); // Run effect only once on mount

  // console.log(publicKey?.toString(), "publicKeypublicKeypublicKey")

  // console.log(tokens?.toString(), "tokenstokens")

  useEffect(() => {
    getAdminSectionValues();
    getUserDataValue();
    getIsUser();
  }, [publicKey, user]);

  useEffect(() => {
    setTokens(0);
  }, [user, loader]);

  useEffect(() => {
    setTokensPrice(0);
    // console.log(tokenPrice?.toString(), "priceeeeeeeeeeeeeeee");
    let toPrice = +tokenPrice * +tokens;
    // console.log(toPrice);
    setTokensPrice(toPrice);
  }, [tokens]);

  // console.log(toatlSoldTokens, "toatlSoldTokenstoatlSoldTokens");

  let totalSoldTokens = Number(toatlSoldTokens); // Assuming half of the tokens are sold
  let soldTokensPercentage =
    (totalSoldTokens * Number(100)) / Number("300000000000000000"); //300000000000000000
  let remainTokensPercentage =
    ((Number("300000000000000000") - totalSoldTokens) * Number(100)) /
    Number("300000000000000000");

  // console.log(remainTokensPercentage.toString(), "remainTokensPercentage");
  // console.log(soldTokensPercentage.toString(), "soldTokensPercentage");

  const onChangeHandler = (e) => {
    if (purchasedToken + +e > 7576) {
      setError(true);
    } else if (+e < 7577) {
      setTokens(e);
      setError(false);
    } else {
      setError(true);
    }
  };

  const updateChartSize = () => {
    const isMobileSize = window.matchMedia("(max-width: 768px)").matches;
    console.log(isMobileSize, "isMobileSizeisMobileSizeisMobileSize");
    setIsMobile(isMobileSize);
  };
  useEffect(() => {
    updateChartSize();
    window.addEventListener("resize", updateChartSize);
    return () => {
      window.removeEventListener("resize", updateChartSize);
    };
  }, []);

  const [saleStarted, setSaleStarted] = useState(false);

  // Set the timer for 10 days from now
  // const tenDays =  5 * 1000;
  // const saleStartTime = Date.now() + tenDays;

  const tenDays = 30 * 24 * 60 * 60 * 1000;
  const saleStartTime = 1726498873443 + tenDays;

  // Countdown renderer
  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Timer has completed
      // setSaleStarted(true);
      return <p>coming soon</p>; // Optionally return null or a placeholder if the countdown is complete
    } else {
      // Render countdown
      return (
        <div className="countdown-sec">
          <span>{days}</span>
          <div>:</div>
          <span>{hours}</span>
          <div>:</div>
          <span>{minutes}</span>
          <div>:</div>
          <span>{seconds}</span>
        </div>
      );
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div className="landing-page">
            <div className="landing-page-wrap">
              <div className="home-sec-1">
                <div className="container">
                  <div className="wrapper">
                    <div className="left">
                      <h1>Welcome to the world of Pawjamas!</h1>
                      <p>
                        where the fun of crypto meets the charm of our adorable
                        friends in pajamas. Pawjamas about laughs and the fun
                        but it's also a promising avenue for long-term
                        investment, because who can resist the allure of cats in
                        cozy sleepwear?
                      </p>
                    </div>

                    {isPresaleStart ? (
                      <div className="right">
                        <div className="main-box">
                          <div className="one">
                            <p>
                              {isPresaleStart
                                ? "PRESALE STAGE 1"
                                : "Coming Soon"}
                            </p>
                          </div>
                          <div className="two">
                            <div className="left">
                              <p>
                                {" "}
                                {isPresaleStart
                                  ? `${soldTokensPercentage}%`
                                  : ""}
                              </p>
                            </div>
                            <div className="right">
                              <p>
                                <span>
                                  {" "}
                                  {isPresaleStart
                                    ? `${remainTokensPercentage}%`
                                    : ""}{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="three">
                            <p>{isPresaleStart ? "Current Price" : ""} </p>
                            <p>
                              {isPresaleStart
                                ? `${tokenPrice ? tokenPrice : "0"}`
                                : ""}{" "}
                            </p>
                          </div>

                          {isPresaleStart && user ? (
                            <>
                              <div className="input-wrapper">
                                <input
                                  type="text"
                                  value={tokens}
                                  placeholder="Please Enter Tokens"
                                  onChange={(e) => {
                                    onChangeHandler(e.target.value);
                                  }}
                                />
                                <button
                                  onClick={() =>
                                    onChangeHandler(7576 - purchasedToken)
                                  }
                                >
                                  Max Buy
                                </button>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "0px 10px",
                                }}
                              >
                                {error &&
                                  (error && purchasedToken >= 7576 ? (
                                    <p
                                      style={{ color: "red", fontSize: "15px" }}
                                    >
                                      You have reached the buying limit!
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Max buying limit is{" "}
                                      {7576 - purchasedToken}
                                    </p>
                                  ))}
                                {!error && (
                                  <p
                                    style={{
                                      color: "#282828",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {Number(tokensPrice)?.toFixed(3)} Sol
                                  </p>
                                )}
                                {
                                  <p
                                    style={{
                                      color: "#282828",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {Number(purchasedToken)?.toFixed(2)}{" "}
                                    Purchased Tokens
                                  </p>
                                }
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          <div className="four">
                            {isPresaleStart && publicKey?.toString() ? (
                              user ? (
                                <>
                                  <button
                                    onClick={() => buyWithSol(tokens)}
                                    disabled={error}
                                  >
                                    Buy
                                  </button>
                                </>
                              ) : (
                                <button onClick={participateUser}>
                                  Participate In Presale
                                </button>
                              )
                            ) : isPresaleStart ? (
                              <WalletMultiButton />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="right">
                        <div className="main-box">
                          <div className="two">
                            <div className="presale">
                              {/* <p>{"Coming Soon"}</p> */}
                              <Countdown
                                date={saleStartTime}
                                renderer={countdownRenderer}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="home-sec-2">
                <div className="container">
                  <div className="wrapper">
                    <div className="left">
                      <img
                        src="/assets/images/home-sec-2-sideImg.png"
                        alt="home-sec-2"
                      />
                    </div>

                    <div className="right">
                      <h1>Discover the Pawjamas Project</h1>
                      <p>
                        Pawjamas wants to foster a crypto community that warms
                        the heart, sparks joy, and looks out for one another. At
                        Pawjamas, we celebrate the lighter side of crypto while
                        recognizing the importance of financial prosperity.
                        That's why we've developed a bot with a proven track
                        record, ensuring tangible benefits for our holders.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-sec-3">
                <h1>WHY PAWJAMAS TOKEN</h1>
                <div className="container mt-5">
                  <div className="wrapper">
                    <div className="">
                      <div className="main-box">
                        <div className="box-1">
                          <p>1</p>
                        </div>
                        <div className="box-desc">
                          <p className="desc">
                            Transforming mundane moments into delightful
                            experiences with a plethora of stickers, GIFs, art,
                            and communal engagement.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="main-box">
                        <div className="box-1">
                          <p>2</p>
                        </div>
                        <div className="box-desc">
                          <p className="desc">
                            Engaging our community with challenges that
                            celebrate love, growth, and the irresistible charm
                            of adorable creatures.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="main-box">
                        <div className="box-1">
                          <p>3</p>
                        </div>
                        <div className="box-desc">
                          <p className="desc">
                            Promising future airdrops and rewards for our loyal
                            holders, active community members, and creative
                            content contributors.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="main-box">
                        <div className="box-1">
                          <p>4</p>
                        </div>
                        <div className="box-desc">
                          <p className="desc">
                            Introducing a bot designed to generate profits for
                            all Pawjamas enthusiasts, adding an exciting
                            dimension to our community-driven ecosystem
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="main-box">
                        <div className="box-1">
                          <p>5</p>
                        </div>
                        <div className="box-desc">
                          <p className="desc">
                            We undergo audits, ensuring both our platform and
                            token adhere to the highest standards of security
                            and compliance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="main-box">
                        <div className="box-1">
                          <p>6</p>
                        </div>
                        <div className="box-desc">
                          <p className="desc">
                            Our commitment extends to monitoring developer
                            coins, safeguarding the integrity of our ecosystem
                            and the trust of our community.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row desc-con-3">
              <p>Click below to View Our Verifications</p>
              <div className="btn-container">
                <button>Solid Proof KYC</button>
              </div>
            </div> */}
                </div>
              </div>
              {/* <div className="home-sec-4" id="sec-4">
                <div className="conatainer">
                  <h1>Token Economy</h1>

                  <CanvasJSChart
                    options={chartOptions}
                    containerProps={{
                      width: isMobile ? "auto" : "100%",
                      height: isMobile ? "210px" : "500px",
                    }}
                    onRef={(ref) => (chartRef.current = ref)}
                  />

                 
                </div>
              </div> */}
              {/* <div className="img-con">
                    <PieChart
                      colors={[
                        "#025B95",
                        "#2E84B8",
                        "#52A8D9",
                        "#8ACDF8",
                        "#CDEDFB",
                        "#004571",
                      ]}
                      series={[
                        {
                          arcLabel: (item) => `${item.value}%`,
                          // arcLabelMinAngle: 45,
                          data,
                        },
                      ]}
                      sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                          fill: "#282828",
                          fontWeight: "bold",
                          fontSize: "18px",
                        },
                      }}
                      {...size}
                    />
                  </div> */}
              {/* <div className="img-con">
                    <img
                      src="/assets/images/home-sec-4-pie.png"
                      alt="pie-chart"
                    />
                    <div className="wrapper-heading">
                      <div className="one">
                        <p>Liquidity</p>
                      </div>
                      <div className="one">
                        <p>Marketing</p>
                      </div>
                      <div className="one">
                        <p> Team tokens</p>
                      </div>
                      <div className="one">
                        <p>Cex listings</p>
                      </div>
                      <div className="one">
                        <p>Presale</p>
                      </div>
                      <div className="one">
                        <p>Airdrops</p>
                      </div>
                    </div>
                  </div>  */}
              <div className="home-sec-5" id="sec-5">
                <div className="container" id="hide-on-mobile">
                  <div className="row">
                    <h1>ROADMAP</h1>
                    <p
                    // style={{
                    //   fontFamily: "bloggersansmedium",
                    //   fontSize: "17px",
                    //   paddingRight: "372px",
                    //   paddingLeft: "379px",
                    //   textAlign: "center",
                    //   marginTop: -"20px",
                    //   marginBottom: "16px",
                    // }}
                    >
                      Hey Pawjamas friends, Now that we've kicked off this
                      journey together, it's time to outline our path to
                      success. This roadmap isn't just a plan—it's a shared
                      vision for where we're headed as a tight-knit team. Your
                      role as investors is crucial, and there are specific steps
                      we can take together to maximize our collective success.
                    </p>
                    <div className="col-4">
                      <div className="left">
                        <div className="one-box">
                          {/* <h5>Pre-Fair Launch</h5> */}
                          <h3>Fair Launch</h3>
                          <p>
                            Building the Pawjamas community. Also in this Q we
                            will be doing our fair launch.
                            <br /> <br />
                          </p>
                        </div>
                        <div className="three-box">
                          <div className="one">
                            <h3> Community Building </h3>
                            <p>
                              If everything goes to our plan we are going to
                              list on a top tier exchange.
                            </p>
                          </div>
                          {/* <div className="two">
                            <h5>Games Release</h5>
                            <p>
                              Games Release Community-driven game development
                              and release, incorporating suggestions and ideas
                              from our users.
                            </p>
                          </div>
                          <div className="three">
                            <h5>SDK Development</h5>
                            <p>
                              SDK Development Releasing a Software Development
                              Kit to enable developers to create and integrate
                              their , further expanding the AIM ecosystem's
                              capabilities.
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="center">
                        <img
                          src="/assets/images/new-home-sec-5-centerImg.png"
                          alt="home-sec-5r"
                        />
                        <div className="three">
                          {/* <h5> Development</h5> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="right">
                        <div className="two-box">
                          <div className="one">
                            {/* <h5>Post-Launch Action Plan:</h5> */}

                            <h3>Exchange listings</h3>
                            <p>We aim to get listed on CoinGecko and Mexc.</p>
                          </div>
                          {/* <div className="two">
                      <h5>NFT  Sales Platform</h5>
                      <p>
                        Unveiling a specialized portal for purchasing unique
                        NFTs, featuring diverse characteristics,
                        including color, character, and voice. This platform
                        will revolutionize how users interact with NFTs.
                      </p>
                    </div> */}
                          {/* <div className="three">
                            <h5>Exchange Listings</h5>
                            <p>
                              Expanding our token's reach by listing on
                              prominent cryptocurrency exchanges, enhancing its
                              accessibility and liquidity in the global market.
                            </p>
                          </div> */}
                        </div>
                        {/* <div className="four-box">
                          <div className="one">
                            <h5>Uitily </h5>
                            <p>
                              In the background we will be continue developing
                              the bot to the relevant chains and make sure it
                              works. It will take roughly 6-8 weeks to get it
                              fully ready. But here's the deal: We're keeping it
                              exclusive. The bot will be available only to
                              Pawjamas holders with a minimum value of $100 or a
                              set number of tokens. Quality over quantity,
                              always. It shouldn’t be used too much to maintain
                              the highest standards. We have a hard cap in mind
                              for the amount of bot users. This means that first
                              come, first serve if you meet the requirements.
                              Bot won’t be available for non-holders of the
                              coin. So let's keep the momentum going, together.
                              Pawjamas to the moon!
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/*------------------------ MOBILE ------------------------------------  */}
                <div className="container" id="show-on-mobile">
                  <div className="row">
                    <h1>ROADMAP</h1>

                    <p>
                      Hey Pawjamas friends, Now that we've kicked off this
                      journey together, it's time to outline our path to
                      success. This roadmap isn't just a plan—it's a shared
                      vision for where we're headed as a tight-knit team. Your
                      role as investors is crucial, and there are specific steps
                      we can take together to maximize our collective success.
                      <br></br>
                      <br></br>
                    </p>

                    <div className="col-12 ">
                      <div className="one-box">
                        <img src="/assets/images/sec-5-Q1.png" alt="sec-5-Q1" />

                        <h5
                          style={{
                            fontFamily: "bloggersansbold",
                            fontSize: "16px",
                            textAlign: "center",
                          }}
                        >
                          Fair Launch
                        </h5>

                        <p>
                          Building the Pawjamas community. Also in this Q we
                          will be doing our fair launch.
                        </p>
                      </div>
                      <div className="two-box ">
                        <img src="/assets/images/sec-5-Q2.png" alt="sec-5-Q1" />
                        <div className="one">
                          <h5>Exchange listings</h5>

                          <p>
                          We aim to get listed on CoinGecko and Mexc.
                          </p>
                        </div>
                        <div className="two">
                          {/* <h5>NFT Sales Platform</h5>
                          <p>
                            Unveiling a specialized portal for purchasing unique
                            NFTs, featuring diverse characteristics, including
                            color, character, and voice. This platform will
                            revolutionize how users interact with NFTs.
                          </p> */}
                        </div>
                        <div className="three">
                          {/* <h5>Exchange Listings</h5>
                          <p>
                            Expanding our token's reach by listing on prominent
                            cryptocurrency exchanges, enhancing its
                            accessibility and liquidity in the global market.
                          </p> */}
                        </div>
                      </div>
                      <div className="three-box ">
                        <img src="/assets/images/sec-5-Q3.png" alt="sec-5-Q1" />
                        <div className="one">
                          <h5>Community Building</h5>

                          <p>
                          If everything goes to our plan we are going to list on a top tier exchange.
                          </p>
                        </div>
                        <div className="two">
                          {/* <h5> Games Release</h5>
                          <p>
                            Games Release Community-driven game development and
                            release, incorporating suggestions and ideas from
                            our users.
                          </p> */}
                        </div>
                        <div className="three">
                          {/* <h5> SDK Development</h5>
                          <p>
                            SDK Development Releasing a Software Development Kit
                            to enable developers to create and integrate their ,
                            further expanding the AIM ecosystem's capabilities.
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-sec-6" id="sec-6">
                <div className="container">
                  <h1>Our Team</h1>
                  <Sliders />
                </div>
              </div>
              <div className="home-sec-7" id="sec-7">
                <div className="container">
                  <div className="wrapper">
                    <div className="left">
                      <h1>ABOUT US</h1>
                      <p>
                        Ever wonder who's behind the scenes of your favorite
                        cryptocurrency projects? Allow us to introduce
                        ourselves: we're the founders of Pawjamas, a dynamic
                        quartet of Dutch crypto investors who turned into
                        entrepreneurs.
                        <br /> <br /> We're not just business partners; we're
                        four friends who go way back. Picture this: the year is
                        2017, and the crypto market is booming like never
                        before. That's when fate brought us together, and we've
                        been inseparable ever since.
                        <br /> <br />
                        Originally from the Netherlands, we bonded over our
                        shared passion for trading during the legendary bull run
                        of 2017. As the market soared to new heights, so did our
                        friendship, and we decided to ride the wave together.
                        Fast forward seven years, and here we are, still going
                        strong. They say seven is a lucky number, and we
                        couldn't agree more. It's been seven years of laughter,
                        late-night trading sessions, and countless ups and downs
                        in the crypto world.
                        <br /> <br />
                        But through it all, one thing has remained constant: our
                        shared vision for Pawjamas.
                      </p>
                    </div>

                    <div className="right">
                      <img src="/assets/images/home-sec-7-sideImg.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-sec-8" id="sec-8">
                <div className="home-sec-8-wrap">
                  <h1>Frequently Ask Questions</h1>
                  <FAQ />
                  {/* <ul>
              <li
                onClick={() => {
                  setActive(0);
                }}
              >
                <div className="one">
                  Q.1 What is Pawjamas Token?
                  {active !== 0 && (
                    <svg
                      width="29"
                      height="6"
                      viewBox="0 0 29 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="29" height="6" rx="3" fill="#282828" />
                    </svg>
                  )}
                  {active === 0 && (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z"
                        fill="#F49B15"
                      />
                    </svg>
                  )}
                </div>
                {active === 0 && (
                  <div className="two">
                    Pawjamas Token is the native cryptocurrency of our AI Meme
                    ecosystem, designed to facilitate transactions, access
                    unique AI services, and enable community participation
                    within our innovative virtual world. It's a versatile
                    digital asset that powers our ecosystem's economy.
                  </div>
                )}
              </li>
              <li
                onClick={() => {
                  setActive(1);
                }}
              >
                <div className="one">
                  Q.2 What is Pawjamas Token?
                  {active !== 1 && (
                    <svg
                      width="29"
                      height="6"
                      viewBox="0 0 29 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="29" height="6" rx="3" fill="#282828" />
                    </svg>
                  )}
                  {active === 1 && (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z"
                        fill="#F49B15"
                      />
                    </svg>
                  )}
                </div>
                {active === 1 && (
                  <div className="two">
                    Pawjamas Token is the native cryptocurrency of our AI Meme
                    ecosystem, designed to facilitate transactions, access
                    unique AI services, and enable community participation
                    within our innovative virtual world. It's a versatile
                    digital asset that powers our ecosystem's economy.
                  </div>
                )}
              </li>
              <li
                onClick={() => {
                  setActive(2);
                }}
              >
                <div className="one">
                  Q.3 What is Pawjamas Token?
                  {active !== 2 && (
                    <svg
                      width="29"
                      height="6"
                      viewBox="0 0 29 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="29" height="6" rx="3" fill="#282828" />
                    </svg>
                  )}
                  {active === 2 && (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z"
                        fill="#F49B15"
                      />
                    </svg>
                  )}
                </div>
                {active === 2 && (
                  <div className="two">
                    Pawjamas Token is the native cryptocurrency of our AI Meme
                    ecosystem, designed to facilitate transactions, access
                    unique AI services, and enable community participation
                    within our innovative virtual world. It's a versatile
                    digital asset that powers our ecosystem's economy.
                  </div>
                )}
              </li>
              <li
                onClick={() => {
                  setActive(3);
                }}
              >
                <div className="one">
                  Q.4 What is Pawjamas Token?
                  {active !== 3 && (
                    <svg
                      width="29"
                      height="6"
                      viewBox="0 0 29 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="29" height="6" rx="3" fill="#282828" />
                    </svg>
                  )}
                  {active === 3 && (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z"
                        fill="#F49B15"
                      />
                    </svg>
                  )}
                </div>
                {active === 3 && (
                  <div className="two">
                    Pawjamas Token is the native cryptocurrency of our AI Meme
                    ecosystem, designed to facilitate transactions, access
                    unique AI services, and enable community participation
                    within our innovative virtual world. It's a versatile
                    digital asset that powers our ecosystem's economy.
                  </div>
                )}
              </li>
              <li
                onClick={() => {
                  setActive(4);
                }}
              >
                <div className="one">
                  Q.5 What is Pawjamas Token?
                  {active !== 4 && (
                    <svg
                      width="29"
                      height="6"
                      viewBox="0 0 29 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="29" height="6" rx="3" fill="#282828" />
                    </svg>
                  )}
                  {active === 4 && (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z"
                        fill="#F49B15"
                      />
                    </svg>
                  )}
                </div>
                {active === 4 && (
                  <div className="two">
                    Pawjamas Token is the native cryptocurrency of our AI Meme
                    ecosystem, designed to facilitate transactions, access
                    unique AI services, and enable community participation
                    within our innovative virtual world. It's a versatile
                    digital asset that powers our ecosystem's economy.
                  </div>
                )}
              </li>
            </ul> */}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default LandingPage;
