import React from 'react'

const Loader = () => {
    return (
        <div className="Loadermain"
            style={{
                width: '100vw',
                height: '100vh',
                background: '#000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >

            <div class="loader"></div>

        </div>
    )
}

export default Loader