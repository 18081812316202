import React, { useContext, useEffect, useState, useRef } from 'react';
import { Store } from '../../Phantom/PhantomWallet';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { readRemoteFile, usePapaParse } from 'react-papaparse';
import * as XLSX from 'xlsx'
import toast from 'react-hot-toast';
import Loader from '../../Components/Loader';
import { Footer, Header } from '../../Components';
import { useWallet } from '@solana/wallet-adapter-react';

const AdminPage = () => {

    const { getAdminSectionValues, createPreSaleStart,
        isPresaleStart,
        depositPresaleTokens,
        airdropTokens,
        loader,
        // handlePayment,
        saleStart,
        initializeATA,
        isInitiliseStatePDA,
        isInitiliseTokenPDA,
        getUserDataValue,
        saleStop } = useContext(Store)
    const { publicKey, signTransaction, sendTransaction } = useWallet();

    const [recipientAddresses, setRecipientAddresses] = useState([]);
    const [airdropAmount, setAirdropAmount] = useState(0);
    // console.log(isInitiliseStatePDA,isInitiliseTokenPDA, isPresaleStart, "isInitiliseStatePDA isInitiliseTokenPDA isPresaleStart ..")
    const [associatedAccountState, setAssociatedAccountState] = useState(false);
    const [driveAccountState, setDriveAccountState] = useState(false);

    const { readString } = usePapaParse();
    const file = useRef(null);


    // console.log(publicKey, "publicKeypublicKey")

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileExtension === 'csv') {
            try {
                const csvData = await parseCSV(file);
                checkDuplicateAddresses(csvData);
                setRecipientAddresses(csvData);
            } catch (error) {
                toast.error('Error parsing CSV file: ' + error.message);
            }
        } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
            try {
                const excelData = await parseExcel(file);
                checkDuplicateAddresses(excelData);
                setRecipientAddresses(excelData);
            } catch (error) {
                toast.error('Error parsing Excel file: ' + error.message);
            }
        } else {
            toast.error('Unsupported file format. Please upload a CSV or Excel file.');
        }
    };

    const checkDuplicateAddresses = (data) => {
        const addressSet = new Set();
        for (const address of data) {
            if (addressSet.has(address)) {
                toast.error('Duplicate address found: ' + address);
            }
            addressSet.add(address);
        }
    };

    const parseCSV = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                readString(e.target.result, {
                    header: false, // Assuming the CSV doesn't have headers
                    dynamicTyping: true,
                    complete: (results) => {
                        const csvData = results.data.map((row) => row[0]); // Assuming addresses are in the first column
                        resolve(csvData);
                    },
                    error: (error) => {
                        toast.error('CSV parsing error: ' + error.message);
                        reject(error);
                    },
                });
            };
            reader.onerror = (error) => {
                toast.error('CSV file reading error: ' + error.message);
                reject(error);
            };
            reader.readAsText(file);
        });
    };

    const parseExcel = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                const excelData = [];

                workbook.SheetNames.forEach((sheetName) => {
                    const sheet = workbook.Sheets[sheetName];
                    const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                    for (let i = 0; i < rows.length; i++) {
                        const address = rows[i][0]; // Assuming addresses are in the first column
                        excelData.push(address);
                    }
                });

                resolve(excelData);
            };

            reader.onerror = (error) => {
                toast.error('Excel file reading error: ' + error.message);
                reject(error);
            };
            reader.readAsBinaryString(file);
        });
    };

    const addList = async () => {
        // console.log(recipientAddresses, "fileDatafileDatafileDatafileData");
        if (airdropAmount == 0) {
            return toast.error("Please input Airdrop amount");
        }
        airdropTokens(recipientAddresses, airdropAmount);
        // file.current.value = null;
        // setRecipientAddresses([]);
    };

    const associatedAccountFunction = async () => {
        const status = await initializeATA();

        if (status) setAssociatedAccountState(status ? status : false)
    }

    const driveAccountFunction = async () => {
        const status = await createPreSaleStart();

        if (status) setDriveAccountState(status ? status : false)
    }


    useEffect(() => { }, [associatedAccountState, driveAccountState])

    useEffect(() => {
        (function () {
            setDriveAccountState(isInitiliseStatePDA);
            setAssociatedAccountState(isInitiliseTokenPDA);
        })();
    }, [isInitiliseStatePDA, isInitiliseTokenPDA])


    // console.log(associatedAccountState,driveAccountState,"associatedAccountState driveAccountState")


    useEffect(() => {
        getAdminSectionValues();
        getUserDataValue();
    }, [publicKey])
    return (
        <>
            {!publicKey ?
                <div className="admin-dashboard">
                    <WalletMultiButton />
                </div>
                : publicKey?.toBase58() !== process.env.REACT_APP_OWNER_WALLET ?

                <div className="admin-dashboard">
                    <WalletMultiButton />
                    <div className='displayMessage'>
                    <h1 style={{display:'flex'}}>Please connect with Admin Account</h1>
                    </div>
                </div>
                    :
                    loader ? <Loader />
                        :
                        <>
                            <Header />
                            {publicKey ?
                                <div className="admin-dashboard">
                                    <button className={`${driveAccountState ? "disable" : ""}`} onClick={() => driveAccountFunction()}>Create Presale</button>
                                    <button className={`${(driveAccountState && associatedAccountState) ? "disable" : ""}`} onClick={() => associatedAccountFunction()}>Initiate Account</button>
                                    <button onClick={depositPresaleTokens}>Deposit Presale Funds</button>
                                    <button className={`${driveAccountState && isPresaleStart ? "disable" : ""}`} onClick={() => saleStart()}>Start Sale</button>
                                    <button className={`${driveAccountState && !isPresaleStart ? "disable" : ""}`} onClick={() => saleStop()}>Stop Sale</button>
                                    <div className="airdrop-section">
                                        <input
                                            type="number"
                                            value={airdropAmount}
                                            onChange={(e) => setAirdropAmount(e.target.value)}
                                            placeholder="Airdrop Amount"
                                        />
                                        <div>
                                            <label htmlFor="file-upload">Upload CSV or XLSX file:</label>
                                            <input ref={file} type="file" accept=".csv, .xlsx, .xls" onChange={handleFileChange} />
                                        </div>
                                        <button onClick={() => addList()} className="whitelist-button">Air Drop</button>

                                    </div>
                                </div>

                                :
                                <div className="admin-dashboard">
                                    <WalletMultiButton />
                                </div>
                            }
                            <Footer />
                        </>
            }
        </>);
};

export default AdminPage;