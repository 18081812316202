// import React, { useState } from "react";

// const FAQ = () => {
//   const [active, setActive] = useState();

//   return (
//     <ul>
//       <li
//         onClick={() => {
//           setActive(10);
//         }}
//       >
//         <div className="one">
//           Q.1 Why the name Pawjamas?
//           {active !== 10 && (
//             <svg
//               width="29"
//               height="6"
//               viewBox="0 0 29 6"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <rect width="29" height="6" rx="3" fill="#282828" />
//             </svg>
//           )}
//           {active === 10 && (
//             <svg
//               width="14"
//               height="14"
//               viewBox="0 0 12 12"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z"
//                 fill="#282828"
//               />
//             </svg>
//           )}
//         </div>
//         {active === 10 && (
//           <div className="two">
//             Let’s be honest. Who doesn’t love cats? They are the unofficial mascots of the internet. Life’s too short not to have a little fun.
//             It’s getting even better when they’re wearing pajamas. That’s when it hit us: Pawjamas.
//           </div>
//         )}
//       </li>
//       <li
//         onClick={() => {
//           setActive(0);
//         }}
//       >
//         <div className="one">
//           Q.2 How to buy?
//           {active !== 0 && (
//             <svg
//               width="29"
//               height="6"
//               viewBox="0 0 29 6"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <rect width="29" height="6" rx="3" fill="#282828" />
//             </svg>
//           )}
//           {active === 0 && (
//             <svg
//               width="14"
//               height="14"
//               viewBox="0 0 12 12"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z"
//                 fill="#282828"
//               />
//             </svg>
//           )}
//         </div>
//         {active === 0 && (
//           <div className="two">
//           Link your Solana wallet to the website. Select the amount of Solana (max 2.5 SOL) and buy your tokens.
//           </div>
//         )}
//       </li>
//       <li
//         onClick={() => {
//           setActive(1);
//         }}
//       >
//         <div className="one">
//           Q.3 Is Pawjamas an official registered company?
//           {active !== 1 && (
//             <svg
//               width="29"
//               height="6"
//               viewBox="0 0 29 6"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <rect width="29" height="6" rx="3" fill="#282828" />
//             </svg>
//           )}
//           {active === 1 && (
//             <svg
//               width="14"
//               height="14"
//               viewBox="0 0 12 12"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z"
//                 fill="#282828"
//               />
//             </svg>
//           )}
//         </div>
//         {active === 1 && (
//           <div className="two">
// Yes, Pawjamas is an official registered company in the USA. You can see the documents on the website.
//           </div>
//         )}
//       </li>
//       <li
//         onClick={() => {
//           setActive(2);
//         }}
//       >
//         <div className="one">
//           Q.4 When will pawjamas actually launch?
//           {active !== 2 && (
//             <svg
//               width="29"
//               height="6"
//               viewBox="0 0 29 6"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <rect width="29" height="6" rx="3" fill="#282828" />
//             </svg>
//           )}
//           {active === 2 && (
//             <svg
//               width="14"
//               height="14"
//               viewBox="0 0 12 12"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z"
//                 fill="#282828"
//               />
//             </svg>
//           )}
//         </div>
//         {active === 2 && (
//           <div className="two">
//            Directly after the closure of the presale.
//           </div>
//         )}
//       </li>
//       <li
//         onClick={() => {
//           setActive(3);
//         }}
//       >
//         <div className="one">
//           Q.5 Did the full team did KYC?
//           {active !== 3 && (
//             <svg
//               width="29"
//               height="6"
//               viewBox="0 0 29 6"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <rect width="29" height="6" rx="3" fill="#282828" />
//             </svg>
//           )}
//           {active === 3 && (
//             <svg
//               width="14"
//               height="14"
//               viewBox="0 0 12 12"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z"
//                 fill="#282828"
//               />
//             </svg>
//           )}
//         </div>
//         {active === 3 && (
//           <div className="two">
//      Yes, all of the founders have done their KYC procedure, done by Solidproof.io
//           </div>
//         )}
//       </li>
//     </ul>
//   );
// };

// export default FAQ;


import React, { useState } from "react";

const FAQ = () => {
  const [active, setActive] = useState();

  const toggleActive = (index) => {
    setActive(active === index ? undefined : index);
  };

  const questions = [
    {
      question: "Q.1 Why the name Pawjamas?",
      answer: "Let’s be honest. Who doesn’t love cats? They are the unofficial mascots of the internet. Life’s too short not to have a little fun. It’s getting even better when they’re wearing pajamas. That’s when it hit us: Pawjamas.",
    },
    {
      question: "Q.2 How to buy?",
      answer: "Link your Solana wallet to the website. Select the amount of Solana (max 2.5 SOL) and buy your tokens.",
    },
    {
      question: "Q.3 Is Pawjamas an official registered company?",
      answer: "Yes, Pawjamas is an official registered company in the USA. You can see the documents on the website.",
    },
    {
      question: "Q.4 When will Pawjamas actually launch?",
      answer: "Directly after the closure of the presale.",
    },
    {
      question: "Q.5 Did the full team do KYC?",
      answer: "Yes, all of the founders have done their KYC procedure, done by Solidproof.io.",
    },
  ];

  return (
    <ul>
      {questions.map((item, index) => (
        <li key={index} onClick={() => toggleActive(index)}>
          <div className="one">
            {item.question}
            {active !== index ? (
              <svg width="29" height="6" viewBox="0 0 29 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="29" height="6" rx="3" fill="#282828" />
              </svg>
            ) : (
              <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7803 1.28033C12.0732 0.987437 12.0732 0.512563 11.7803 0.21967C11.4874 -0.0732233 11.0126 -0.0732233 10.7197 0.21967L11.7803 1.28033ZM0.21967 10.7197C-0.0732233 11.0126 -0.0732233 11.4874 0.21967 11.7803C0.512563 12.0732 0.987437 12.0732 1.28033 11.7803L0.21967 10.7197ZM1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.28033 0.21967ZM10.7197 11.7803C11.0126 12.0732 11.4874 12.0732 11.7803 11.7803C12.0732 11.4874 12.0732 11.0126 11.7803 10.7197L10.7197 11.7803ZM10.7197 0.21967L0.21967 10.7197L1.28033 11.7803L11.7803 1.28033L10.7197 0.21967ZM0.21967 1.28033L10.7197 11.7803L11.7803 10.7197L1.28033 0.21967L0.21967 1.28033Z" fill="#282828" />
              </svg>
            )}
          </div>
          {active === index && (
            <div className="two">
              {item.answer}
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default FAQ;
